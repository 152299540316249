<!--
 * @Auth: linjituan
 * @Date: 2021-12-20 17:08:16
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-09-21 15:25:37
-->
<template>
  <page-header-wrapper>
    <a-spin :spinning="loading" tip="加载中...">
      <div :style="'height:' + height">
        <iframe :src="src" frameborder="no" style="width: 100%;height: 100%" scrolling="auto" />
      </div>
    </a-spin>
  </page-header-wrapper>
</template>
  <script>
  import storage from 'store'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  export default {
    name: 'User',
    components: {},
    data() {
      return {
        src: '',
        height: document.documentElement.clientHeight - 130 + 'px;',
        loading: true
      }
    },
    filters: {},
    created() {
      const token = storage.get(ACCESS_TOKEN)
      this.src = 'http://172.25.1.163:8001/#/auth?callback=' + encodeURIComponent('/system/user?isIframe=true') + '&token=' + token
    },
    computed: {},
    watch: {},
    mounted: function() {
      setTimeout(() => {
        this.loading = false
      }, 230)
      const that = this
      window.onresize = function temp() {
        that.height = document.documentElement.clientHeight - 130 + 'px;'
      }
    },
    methods: {}
  }
  </script>
